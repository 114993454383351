


















































import { SfButton, SfSearchBar } from '@storefront-ui/vue';
import {
  defineComponent, ref, watch, useRoute,
} from '@nuxtjs/composition-api';
import { debounce } from 'lodash-es';
import { clickOutside } from '~/components/directives/click-outside/click-outside-directive';
import SvgImage from '~/components/General/SvgImage.vue';
import { useProduct } from '~/modules/catalog/product/composables/useProduct';
import { Products } from '~/modules/GraphQL/types';
import { entries } from 'idb-keyval';
import { Logger } from '~/helpers/logger';
export default defineComponent({
  name: 'SearchBar',
  components: {
    SfSearchBar,
    SfButton,
    SvgImage,
  },
  directives: { clickOutside },
  props: {
    isSearchOpen: {
      type: Boolean,
      default: false,
    },
    itemsPerPage: {
      type: Number,
      default: 12,
    },
    minTermLen: {
      type: Number,
      default: 3,
    },
  },
  emits: ['set-is-open', 'set-search-results', 'set-search-results-offline'],
  setup(props, { emit }) {
    const term = ref('');
    const route = useRoute();

    const { getProductList } = useProduct();

    const showSearch = () => {
      if (!props.isSearchOpen) {
        emit('set-is-open', true);
        if (document) {
          document.body.classList.add('no-scroll');
        }
      }
    };


    const isOffline = () => {
      if(typeof window != 'undefined') {
        if(localStorage.getItem('isOffline') == 'true') {
          return true;
        }
      }
      return false;
    }

    const hideSearch = () => {
      if (props.isSearchOpen) {
        emit('set-is-open', false);
        emit('set-search-results', null);
        emit('set-search-results-offline', null);
        if (document) {
          document.body.classList.remove('no-scroll');
        }
      }
    };

    const toggleSearch = () => {
      if (props.isSearchOpen) {
        hideSearch();
      } else {
        showSearch();
      }
    };

    const closeSearch = (event: MouseEvent) => {
      if (document) {
        const searchResultsEl = document.querySelector('.search');
        const closeTriggerElement = event.target as HTMLElement;

        if (!searchResultsEl?.contains(closeTriggerElement)) {
          hideSearch();
          term.value = '';
        }
      } else {
        hideSearch();
        term.value = '';
      }
    };

    const rawHandleOfflineSearch = async (searchTerm: string) => {
      term.value = searchTerm;
      if (term.value.length < props.minTermLen) return;

      entries().then(function(entries) {
        let productList = {items: [], total_count: 0, page_info: {}, aggregations: []};
        var searchedRecords = [];
        for (const data of entries)
        {
          if(data[0].toString().startsWith('prod_')) {
            if(data[1].name.toLowerCase().indexOf(term.value) !== -1){
              searchedRecords.push(data[1]);
            }
          }

        }
        if(searchedRecords.length > 0) {
          productList.items = searchedRecords;
          productList.total_count = productList.items.length;
          productList.page_info = {
            current_page: 1,
            page_size: 12,
            total_pages: 1,
          };
          productList.aggregations = [
            {
              attribute_code: 'price',
              label: 'Price',
              options: []
            },
            {
              attribute_code: '"category_id"',
              label: 'Category',
              options: []
            }
          ];
        } else {
          // productList.items =  {};
          productList.total_count = 0;
        }
        emit('set-search-results-offline', productList!.items);
      });
    };

    const rawHandleSearch = async (searchTerm: string) => {
      term.value = searchTerm;
      if (term.value.length < props.minTermLen) return;

      // M2-579
      const productList : Products = await getProductList({
        pageSize: props.itemsPerPage,
        search: term.value,
      }) as unknown as Products;
      emit('set-search-results', productList!.items);
    };

    const debouncedHandleSearch = debounce(rawHandleSearch, 1000);

    const debouncedHandleOfflineSearch = debounce(rawHandleOfflineSearch, 1000);

    const debouncedRedirectSearchToProperMethod = function($event) {
      if (isOffline()) {
        return debouncedHandleOfflineSearch($event);
      }
      return debouncedHandleSearch($event);
    }

    const handleKeydownEnter = function(searchTerm: string) {
      // cancel debounce timeout started by typing into the searchbar - this is to avoid making two network requests instead of one
      if (isOffline()) {
        debouncedHandleOfflineSearch.cancel();
        rawHandleOfflineSearch(searchTerm);
      } else {
        debouncedHandleSearch.cancel();
        rawHandleSearch(searchTerm);
      }
    };

    watch(route, () => {
      hideSearch();
      term.value = '';
    });

    return {
      closeSearch,
      showSearch,
      hideSearch,
      toggleSearch,
      rawHandleSearch,
      rawHandleOfflineSearch,
      debouncedHandleSearch,
      debouncedHandleOfflineSearch,
      debouncedRedirectSearchToProperMethod,
      handleKeydownEnter,
      isOffline,
      term,
    };
  },
});

